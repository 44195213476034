const EN = {
    "WELCOME":"Welcome!",
    "LOGIN_SUBTITLE":"Please insert the data required.",
    "CASHIER_USER":"Cashier user",
    "PASSWORD":"Password",
    "REMEMBERME":"Remember me.",
    "LOGIN":"Login",
    "LOGIN_ALERT" : "The user or password is incorrect",
    
    "TICKET_MISSING": "Ticket missing",
    "CHECK_DATA" : "Check data below and start survey when ready.",
    "START_SURVEY":"Start Survey",
    'AMOUNT':'Amount',
    'NUM_GUESTS':"Number of guests",
    "CONFIRM_NUMBER": "Confirm number of guests (waiter reports ",
    "MANAGER":'Manager',
    "OPERATOR":'Asistant manager',
    "NUM_TABLE": "# Table",
    "NUM_TICKET": "# Ticket",
    "WAITER":'Waiter',

    "GOAL" : "Our goal is",
    "PERFECTION" : '"Perfection" at Quince.',
    "HOW_DONE" : "Please share how we did today.",
    
    "GASTRONOMIC_TITLE": "Do you rate all food served today 5-stars?",
    "CONTINUE": "Continue",
    "TEXT_AREA_TITLE_FOOD": "Any other comments on your food please type here.",
    "TEXT_AREA_TITLE_DRINK": "Please type here if you have any other comments on your drinks",
    
    "GASTRONOMIC_TITLE_DRINK": "Do you rate all drinks served today 5-stars?",
    "DRINKS_SUBTITLE": "Rate the Drinks ordered at your table",
    "GASTRONOMIC_RATE_ITEMS_DRINK": "Please only rate the items you or your table experienced.",
    "DRINK_ITEMS1": "out",
    "DRINK_ITEMS2": "items rated",
    "DRINK_PLACEHOLDER": "Provide any general drink comments for any item",

    "FOOD_SUBTITLE": "Rate the food ordered at your table",
    "GASTRONOMIC_RATE_ITEMS_FOOD": "Please only rate the items you or your table experienced.",
    "FOOD_ITEMS1": "out",
    "FOOD_ITEMS2": "items rated",
    "FOOD_PLACEHOLDER": "Provide any general food comments for any item",
    "BREAKFAST": "Breakfast",
    
    "END_SURVEY": "End survey anytime",
    "THANK_YOU":"Thank you",
    "HAVE_EMAIL": "We have your email and we",
    "WILL_CONTACT": "will be in contact.",

    "NOT_RATED": "Currently not rated",
    "RATED_WITH": "You rated",
    "STARS": "stars",

    "CONTACT_INFO": "Provide your contact information to receive our specials.",
    "CONTACT_VIA": "Contact via",
    "PHONE": "Phone",
    "EMAIL":"E-mail",
    "MOBILE":"Mobile",
    "FIRST_NAME":"First name",
    "LAST_NAME": "Last name",
    "WANT_CONTACT": "Do you want us to contact you?",
    "YES": "Yes",
    "NO": "No",
    "LIKE_MANAGEMENT": "Would you like management to contact you for any reason?",
    "WICH_REASON":"What is the reason?",
    "COMPLEMENT_US":"To complement us",
    "HAD_PROBLEM": "Had a problem",
    "OTHER_REASON": "Other reason",
    "WHAT_REASON":"What is the reason?",

    "RATE_MANAGER":"Rate manager",
    "SELECT_MANAGER":"Select the manager who checked your tables’ experience today.",

    "RATE":"Rate your experience",
    "RATE_CONNECTOR":"with",
    "LOW_RATING1":"Oh,", 
    "LOW_RATING2":"stars is not our best... what can",
    "LOW_RATING3":"have done better?",
    "LOW_RATING4":"A very low rating... please tell us what",
    "LOW_RATING5":"can do better next time.",
    
    
    "DEMEANOR":"Demeanor",
    "VERY_FRIENDLY":"Very friendly",
    "SEMI_FRIENDLY":"Semi-friendly",
    "Ok":"Ok",
    "UNFRIENDLY":"Unfriendly",
    
    "FOLLOW_UP":'Follow up',
    "VERY_HELPFUL":'Very helpful',
    "NO_HELP":'Ok',
    "NO_HELPFUL":'Not helpful when needed',

    "RATE_HOSTESS":"Rate our host who received you today.",
    "SELECT_HOST":"Host at reception",
    "THAT_RATING": "Tell us more so ",
    "THAT_RATING_COMPLEMENT":" can get 5 stars next time.",

    "ATTITUDE":"Attitude",
    "ALWAYS_SMILING":"Always smiling",
    "SMILING_ENOUGH":"Ok",
    "FROWNING":"Frowning",

    "COMMUNICATION":"Communication",
    "GREAT":"Great",
    "OK":"Ok",
    "POOR":"Poor",

    "OVERALL": "Rate your overall experience.",
    "WHICH_PERFECT": 'Which member of our team asked, how was your experience at Quince?',
    "OTHER": "Other",
    "M/C": "Manager/Captain",
    "SERVER": "Server",
    "NOBODY": "No one",
    "PERFECT_MANAGER": "Manager",
    "CAPTAIN": "Captain",
    "NO_REMEMBER": "I don't remember",

    "DONT_REMEMBER":"Don't remember",
    "OPORTUNITY_MET_CHEF":"Did you meet a Quince chef today?",

    "RATE_TABLE":"Rate table service.",
    "REALLY_STARS":"Tell us more so ",
    "REALLY_STARS_COMPLEMENT":" can get 5 stars next time.",
    "SERVICE": "'s service.",

    "PACE": "Pace",
    "MOVE_FAST": "Moved fast",
    "OK_SPEED":"Ok Speed",
    "SNAIL_PACE":"Snail pace",

    "INTERACTIONS":"Interactions",
    "CONFORTABLE":"Comfortable interactions",
    "TOO_MANY_INTERRUPTIONS":"Too many interruptions",

    "ANSWERED_MENU":"Answered menu questions",

    "PERFECTLY":"Perfectly",
    "MENU_OK": "Ok",
    "NOT_GOOD":"Not good",

    "FOOD_WAS":"Food delivery was...",
    "PERFECT":"Perfect",
    "SLOW":"Slow",
    "NO_OPINION":"No opinion",
    "NO_PROBLEM":"No problem",


    "FOOD_TEMPERATURE":"Food temperature was...",
    "ADECUATE":"Ok",
    "INCORRECT":"Incorrect",    

    "DRINK_WAS":"Your drink service was...",
    "FAST_FLOWING":"Fast & flowing",
    "BARRA_OK": "Ok",
    "ALWAYS_DELAYED":"Always delayed",
    "MUSIC": "Music",
    "MUSIC_TITLE": "Our music is for your comfort and happiness.",
    "MUSIC_ADVISE": "Please advise us more. Problem with music was?",
    "WRONG_GENRE": "Wrong genre",
    "TOO_LOUD": "Too loud",
    "NOT_FAMILIAR": "Not familiar",
    "OTHER_PROBLEM": "Other problem",

    "RESERVATIONS":"Was our Host team professional when booking your table?",
    "RESERVATION_NO_OPINION":"I booked online",
    "RESERVATION_OK":"Professional / Friendly",
    "RESERVATION_PROBLEM":"Not Professional / Not Friendly",
    
    "VERY_HELP_OPTIONS":"Very helpful with options",
    "NO_HELP_OPTIONS":"Not helpful with options",

    "HOW_MANY_MINUTES":"How many minutes did you wait at the host stand?",
    "MORE_HOUR":"+ 1 hour",

    "COURTESY_DRINK":'Were you offered a courtesy drink while you waited?',
    "BETTER_DRINK":"Waiting is better with a drink, don't you think",
    "ESTIMATE_MINUTES": "How much time did it take to receive your bill after you requested?",

    "LOGOUT": "Log out",

    "FINISHED" : "Are you finished with the survey?",
    "YOUR_PERCENT":"You have completed ",
    "YES_DONE":'Yes, I am done',
    "NO_GOBACK": 'No, go back',

    "THANK_YOUR_TIME": "Thank you for your time ",
    "ON_TOUCH": "We have your email and will be in touch soon.",
    "LEARN_MORE":"Would you like to learn more about our",
    "SURVEY_SYSTEM": "survey system for your business?",
    "YES_LEARN":"Yes, I want to learn",

    "THANK_YOU_TIME":"Thank you.",
    "ENJOY_VISIT":"Our team truly appreciates your feedback and helping them be the best they can be.",

    "RECEIVED":"We have recived your contact and business info.",
    "TOUCH_SOON":" We will be in touch soon.",

    "TO_JOIN":"To join the “Quince crowd” and receive VIP benefits and opportunities please provide your info below.",
    "NOT_SPAM":"We promise not to spam you.",
    "Mr":"Mr.",
    "Ms":"Ms.",
    "NotD":"Not disclosed",

    "YOUR_INFO":"Your info",
    "VIP_PROMO":"I would also like to join the Quince crowd and receive VIP promotions.",

    "PROGRESS_0": "Let's get started",
    "PROGRESS_20": "Nice Start",
    "PROGRESS_30": "You're helping us",
    "PROGRESS_40": "Steady advance",
    "PROGRESS_50": "Keep going",
    "PROGRESS_60": "Half way there...",
    "PROGRESS_70": "Going great...",
    "PROGRESS_80": "Light at end of Tunnel",
    "PROGRESS_90": "So close!!!",
    "PROGRESS_100": "Completed.. the team appreciate you!!!!",

    "REQUIRED":"Required field",
    "REQUIRED_EMAIL_PHONE":"Email or phone required",

    "NAME_COMPANY":"Name of company",
    "TYPE_BUSINESS":"Type of business",
    "CITY":"City of business",
    "NUMBER_LOCATIONS":"Number of locations",
    "POSITION":"Your position",
    "BUSINESS_INFO":"Business info",

    "SEND_INFO":"Send information",
    "INVALID_NUMBER":"This number does not appear to be valid",

    "All Hosts team":"All Hosts team",
    "Don't Remember":"Don't Remember",
    "All Managers Team":"All Manager Team",
    "All Manager Team":"All Manager Team",

    "INVALID_EMAIL":"The email is incorrect",
    "INVALID_REASON":"Select a reason",

    "BUSINESS_TITLE": "Please provide us your information.",

    "NO_SPACE":"No spaces allowed '  ' ",
    "NO_ARROBA":"Must include @ symbol, example: jonhSmith@gmail.com",
    "NO_ARROBA_MORE":"Only 1 @ symbol allowed, example: jonhSmith@gmail.com",
    "NO_SERVICE":"It must include a mail service, for example: gmail, hotmail, yahoo, etc ...",
    "NO_DOT":"It must include a domain (.com, .mx, .xyz, .co, etc ...)",
    "Minutes":"Minutes",

    "Acceptable timing": "Acceptable timing",
    "Small delay": "Small delay",
    "Long delay (asked 1x)": "Long delay (asked 1x)",
    "Long delay (asked more than 1x)": "Long delay (asked more than 2x)",

    "Table": "Table"
}

export default EN;